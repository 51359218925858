// import { userAuth } from "../store"
import { TokenService } from "@/services/service.js";

const baseUrl = () => {
  if (window.location.hostname !== "localhost") {
    return `${window.location.origin}/api/`;
  } else {
    return "http://localhost:8000/";
  }
};

const getHeader = async () => {
  // let Store = ""
  // let Session = userAuth()
  // if(Session !== null && Session.store_selected !== undefined && Session.store_selected.id !== undefined) {
  //     Store = Session.store_selected.id
  // }
  // console.log('STORE_HEADER', Store)
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Store
  };
};

const generateParams = (params) => {
  // console.log(params);
  let queryString = "";
  for (let key in params) {
    // if (!params.hasOwnProperty(key)) continue;
    if (typeof params[key] == "object") {
      params[key].forEach((item, index) => {
        for (let keyItem in item) {
          queryString += `${key}[${index}][${keyItem}]=${encodeURI(
            item[keyItem]
          )}&`;
        }
      });
    } else {
      queryString += `${key}=${encodeURIComponent(params[key])}&`;
    }
  }
  return queryString == "" ? "" : `?${queryString.replace(/&+$/, "")}`;
};

const logOut = async () => {
  await localStorage.removeItem("user_session");
  window.location.reload();
};

const ApiHelpers = {
  get: async (url, params) => {
    // console.log(url, params);
    let Header = await getHeader();
    return new Promise((resolve) => {
      // let uri = baseUrl() + url;
      let uri = baseUrl() + url + generateParams(params);
      // console.log(uri);
      fetch(uri, {
        method: "GET",
        credentials: "include",
        headers: {
          ...Header,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          if (responseData.status === 401) {
            logOut();
            TokenService.removeToken();
            window.location.reload();
          } else {
            if (responseData.status === 404) {
              return resolve({
                status: 404,
                message: "Page Not Found.",
              });
            } else {
              return resolve(responseData);
            }
          }
        })
        .catch((error) => {
          return resolve({
            status: 500,
            message: "Terjadi Kesalahan Server.",
            error,
          });
        });
    });
  },
  post: async (url, body, validationMessage) => {
    let Header = await getHeader();
    return new Promise((resolve) => {
      fetch(baseUrl() + url, {
        method: "POST",
        credentials: "include",
        headers: {
          ...Header,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          if (responseData.status === 401) {
            logOut();
          } else {
            if (responseData.status === 412) {
              if (validationMessage) {
                const errorObject = responseData.results.data;
                for (const key in errorObject) {
                  if (Object.hasOwnProperty.call(errorObject, key)) {
                    const element = errorObject[key];
                    validationMessage[key] = element;
                  }
                }
              }
            }
            if (responseData.status === 404) {
              return resolve({
                status: 404,
                message: "Page Not Found.",
              });
            } else {
              return resolve(responseData);
            }
          }
        })
        .catch((error) => {
          return resolve({
            status: 500,
            message: "Terjadi Kesalahan Server.",
            error,
          });
        });
    });
  },
};

export default ApiHelpers;
