import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authenticated: false,
    loading: false,
    user: {},
    notif: "",
    overlay: {
      show: false,
      text: "Loading..."
    },
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
  },
  mutations: {
    setToken(state, accessToken) {
      state.token = accessToken;
    },

    toggleNav(state) {
      // mutate state
      // state.drawer = state.drawer = !state.drawer;
    },

    setUser(state, accessUser) {
      state.user = accessUser;
      state.authenticated = true;
    },

    setNotif(state, accessNotif) {
      state.notif = accessNotif;
      state.authenticated = true;
    },

    falseToken(state) {
      state.authenticated = false;
      state.user = {};
    },
  },
  actions: {},
  modules: {},
});
