<template>
  <v-main class="bg-gradient-green">
    <router-view />

    <v-overlay z-index="999" opacity="0.5" :value="$store.state.overlay.show">
      <v-chip color="green" text-color="white">
        <v-progress-circular
          indeterminate
          size="16"
          width="2"
          class="mr-4"
        ></v-progress-circular>

        {{ $store.state.overlay.text }}
      </v-chip>
    </v-overlay>

    <v-snackbar
      v-model="$store.state.snackbar.show"
      multi-line
      absolute
      bottom
      :color="$store.state.snackbar.color"
      text
      outlined
      max-width="300"
      style="z-index: 999"
    >
      {{ $store.state.snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="$store.state.snackbar.color"
          text
          v-bind="attrs"
          @click="$store.state.snackbar.show = false"
        >
          BAIK
        </v-btn>
      </template>
    </v-snackbar>
  </v-main>
</template>

<script>
export default {
  name: "LayoutLogin",

  data: () => ({}),

  methods: {},
};
</script>

<style>
html {
  overflow-y: auto !important;
}

.bg-gradient-green {
  background: #4ab762; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4ab762,
    #a8e063
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4ab762,
    #a8e063
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  box-shadow: none !important;
  border: 1px solid #ebebeb;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  border: 0.25px solid #4ab762;
  border-width: 0.25px !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.v-text-field--solo.v-input--is-focused.error--text
  > .v-input__control
  > .v-input__slot {
  background: #f9f9f9 !important;
  border: 0.25px solid #e74c3c !important;
  border-width: 0.25px !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.v-input__prepend-inner {
  margin-right: 6px !important;
}

::placeholder {
  color: rgba(80, 80, 80, 0.3) !important;
  font-size: 16px !important;
}

.v-application .error--text {
  color: unset !important;
  caret-color: unset !important;
}

.checkbox-pwd .v-label {
  font-size: 14px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: unset;
  margin-bottom: unset;
  position: absolute;
  right: 0;
  margin-top: 8px;
}

.v-text-field.v-text-field--enclosed .v-input__control {
  position: relative;
}

.v-text-field input {
  max-width: calc(100% - 25px) !important;
}
</style>