import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Convert from "@/plugins/convert.js";
import { TokenService } from "@/services/service.js";
import "@/assets/scss/main.scss";
import ApiHelpers from "@/services/ApiHelpers.js";

Vue.prototype.$get = ApiHelpers.get;
Vue.prototype.$post = ApiHelpers.post;

Vue.component("base-table", function (resolve) {
  require(["@/components/base/BaseTable.vue"], resolve);
});
Vue.component("base-dialog", function (resolve) {
  require(["@/components/base/BaseDialog.vue"], resolve);
});
Vue.component("base-dialog-confirmation", function (resolve) {
  require(["@/components/base/BaseDialogConfirmation.vue"], resolve);
});
Vue.component("base-snackbar", function (resolve) {
  require(["@/components/base/BaseSnackbar.vue"], resolve);
});
Vue.component("floating-button", function (resolve) {
  require(["@/components/base/FloatingButton.vue"], resolve);
});
Vue.component("button-opsi", function (resolve) {
  require(["@/components/ButtonOpsi.vue"], resolve);
});

Vue.config.productionTip = false;

const originalPush = router.__proto__.push;
router.__proto__.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

new Vue({
  router,
  store,
  vuetify,
  ApiHelpers,
  TokenService,
  Convert,
  render: (h) => h(App),
}).$mount("#app");
