const token = "token";
const user = "user_info";
const notif = "notif";

const TokenService = {
  getToken() {
    return localStorage.getItem(token);
  },

  saveToken(accessToken, accessUser) {
    localStorage.setItem(token, accessToken);
    localStorage.setItem(user, accessUser);
  },

  removeToken() {
    localStorage.removeItem(token);
    localStorage.removeItem(user);
    localStorage.removeItem(notif);
  },
  saveUser(accessUser) {
    localStorage.setItem(user, JSON.stringify(accessUser));
  },

  getUser() {
    return JSON.parse(localStorage.getItem(user));
  },

  saveNotif(accessNotif) {
    localStorage.setItem(notif, accessNotif);
  },

  getNotif() {
    return JSON.parse(localStorage.getItem(notif));
  },
};

export { TokenService };
