import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import id from "vuetify/src/locale/id.ts";

Vue.component("my-component", {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = "id";
    },
  },
});

export default new Vuetify({
  lang: {
    locales: { id },
    current: "id",
  },
});
