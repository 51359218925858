<template>
  <v-main class="overflow-hidden">
    <v-app-bar
      app
      color="#EDEDED"
      class="container--fluid px-2 elevation-0"
      style="border-bottom: 1px solid #d9dee4"
    >
      <v-app-bar-nav-icon @click.stop="setDrawer" />
      <v-toolbar-title
        v-show="!isMobile"
        class="title grey--text text--darken-2 font-weight-bold"
        >Menu {{ $route.meta.title }}</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <div class="mr-1">
        <router-link to="/notification">
          <v-badge 
            v-if="$store.state.notif > 0"
            class="ml-4 cursor-pointer"
            color="orange darken-3"
            overlap>
            <span slot="badge" v-if="$store.state.notif >= 10">
              9+
            </span>
            <span slot="badge" v-else>
              {{ $store.state.notif }}
            </span>
            <span slot="default">
              <v-icon>mdi-bell</v-icon>
            </span>
          </v-badge>
          <v-icon v-else>mdi-bell</v-icon>
        </router-link>
      </div>

      <v-menu left bottom offset-y max-width="300" rounded="0">
        <template v-slot:activator="{ on }">
          <v-list dense class="py-0 transparent">
            <v-list-item class="rounded" v-on="on">
              <v-list-item-avatar tile class="rounded" color="white">
                <span v-if="$store.state.user.img_url == ''">{{
                  $store.state.user.name | initial
                }}</span>
                <v-img v-else :src="$store.state.user.img_url"></v-img>
              </v-list-item-avatar>

              <v-list-item-content
                class="py-0"
                :style="isMobile ? 'max-width: 80px' : 'max-width: 130px'"
              >
                <v-list-item-title class="font-weight-bold"
                  >{{ $store.state.user.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">{{
                  $store.state.user.email
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>mdi-chevron-down</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>

        <v-list dense>
          <v-list-item @click="$router.push({ name: 'profile' })">
            <v-list-item-content>
              <v-list-item-title>Ubah Profil</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-card-account-details-outline</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="$router.push({ name: 'password' })">
            <v-list-item-content>
              <v-list-item-title>Ubah Password</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-form-textbox-password</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider color="white"></v-divider>

        <v-list dense>
          <v-list-item @click="confirmLogout.dialog = true">
            <v-list-item-content>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      :mini-variant.sync="mini"
      mini-variant-width="80"
      class="bg-gradient-green"
    >
      <v-card
        flat
        class="px-4 py-8 d-flex justify-center align-center transparent"
        to="/"
      >
        <v-img
          :max-width="mini ? 60 : 130"
          :src="require('@/assets/images/logo_esoda.png')"
        ></v-img>
      </v-card>

      <!-- <v-divider></v-divider> -->

      <v-list nav class="px-0">
        <template v-for="(nav, index) in menu">
          <v-list-group
            color="#efefef"
            active-class="menu-active"
            v-if="nav.children"
            v-model="nav.active"
            :key="index"
            :prepend-icon="nav.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title class="subtitle-2">{{
                nav.title
              }}</v-list-item-title>
            </template>
            <v-list-item
              active-class="menu-active"
              v-for="(child, i) in nav.children"
              :key="i"
              :to="child.action"
              sub-group
            >
              <v-list-item-action>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title class="subtitle-2">{{
                child.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            active-class="menu-active"
            :key="nav.title"
            :to="nav.action"
            v-model="nav.active"
          >
            <v-list-item-action>
              <v-icon>{{ nav.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title class="subtitle-2">
              {{ nav.title }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item @click="confirmLogout.dialog = true">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-title class="subtitle-2"> Log Out </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <router-view
      class="rounded-lg fill-height"
      style="background-color: #f7f7f7"
    />

    <v-footer inset app padless v-if="!isMobile" color="#f7f7f7">
      <v-col class="text-center grey--text text--darken-2" cols="12">
        {{ new Date().getFullYear() }} — <strong>Otodidak.co.id</strong>
      </v-col>
    </v-footer>

    <v-dialog
      v-model="confirmLogout.dialog"
      persistent
      max-width="340"
      content-class="rounded-lg"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2"
        >
          <v-icon left>mdi-information</v-icon> Konfirmasi Log Out
        </v-card-title>
        <v-card-text style="min-height: 80px"
          >Apakah yakin akan keluar dari sistem?
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#4ab762"
            depressed
            rounded
            @click="doLogout"
            class="text-none white--text text--lighten-3 flex-grow-1"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>
            Ya, keluar
          </v-btn>
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="confirmLogout.dialog = false"
            class="text-none"
            width="100"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay z-index="999" opacity="0.5" :value="$store.state.overlay.show">
      <v-chip color="green" text-color="white">
        <v-progress-circular
          indeterminate
          size="16"
          width="2"
          class="mr-4"
        ></v-progress-circular>

        {{ $store.state.overlay.text }}
      </v-chip>
    </v-overlay>

    <v-snackbar
      v-model="$store.state.snackbar.show"
      multi-line
      absolute
      bottom
      :color="$store.state.snackbar.color"
      text
      outlined
      max-width="300"
      style="z-index: 999"
    >
      {{ $store.state.snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="$store.state.snackbar.color"
          text
          v-bind="attrs"
          @click="$store.state.snackbar.show = false"
        >
          BAIK
        </v-btn>
      </template>
    </v-snackbar>
  </v-main>
</template>

<script>
import { TokenService } from "@/services/service.js";

export default {
  name: "LayoutAdmin",

  data: () => ({
    drawer: null,
    mini: false,
    isMobile: false,

    menu: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        active: false,
        action: "/",
      },
      {
        title: "User",
        icon: "mdi-account",
        active: false,
        action: "/user",
      },
      {
        title: "Publisher",
        icon: "mdi-account-tie",
        active: false,
        action: "/publisher",
      },
      {
        title: "Organisasi",
        icon: "mdi-account-group",
        active: false,
        action: "/organization",
      },
      {
        title: "Kategori Koleksi Kelas",
        icon: "mdi-bookmark-box-multiple",
        active: false,
        action: "/collection",
      },
      {
        title: "Promo Diskon & Voucher",
        icon: "mdi-sale",
        active: false,
        children: [
          {
            title: "Promo Diskon",
            icon: "mdi-tag-multiple",
            active: false,
            action: "/promo-discount",
          },
          {
            title: "Approval Promo Diskon",
            icon: "mdi-tag-arrow-right",
            active: false,
            action: "/approval-promo-discount",
          },
          {
            title: "Voucher",
            icon: "mdi-ticket-percent",
            active: false,
            action: "/voucher",
          },
        ],
      },
      {
        title: "Laporan Transaksi",
        icon: "mdi-chart-bar",
        active: false,
        children: [
          {
            title: "Global",
            icon: "mdi-web",
            active: false,
            action: "/laporan-transaksi-global",
          },
          {
            title: "Organisasi",
            icon: "mdi-office-building",
            active: false,
            action: "/laporan-transaksi-organisasi",
          },
          {
            title: "Publisher",
            icon: "mdi-account-multiple-outline",
            active: false,
            action: "/laporan-transaksi-publisher",
          },
        ],
      },
      {
        title: "Notifikasi",
        icon: "mdi-bell",
        active: false,
        action: "/notification",
      },
    ],

    confirmLogout: {
      dialog: false,
    },
  }),

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  // watch: { 
  //   '$route' (to, from) { 
  //     this.fetchCountNotification()
  //   } 
  // },

  created() {
    if (process.browser) {
      if ('Notification' in window && 'serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', event => {
          // console.log('kkkkk')
          this.fetchCountNotification() // fetch data real time
        })
      }
    }
  },

  mounted() {
    this.setActiveNavigation();
    this.fetchCountNotification()
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    async fetchCountNotification() {

      let response = await this.$get("notification/count");

      if (response.status == 200) {
        // this.count = response.results.data.count_notification_unread;
        TokenService.saveNotif(response.results.data.count_notification_unread);
        this.$store.commit("setNotif", TokenService.getNotif());
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },
    setDrawer() {
      if (this.isMobile) {
        this.mini = false;
        this.drawer = !this.drawer;
      } else {
        this.drawer = true;
        this.mini = !this.mini;
      }
    },
    setActiveNavigation() {
      this.menu.forEach((item, i) => {
        if (item.children) {
          item.children.forEach((child) => {
            if (this.$route.path == child.action) {
              this.menu[i].active = true;
            }
          });
        } else {
          if (this.$route.path == item.action) {
            this.menu[i].active = true;
          }
        }
      });
    },
    async doLogout() {
      this.confirmLogout.dialog = false;
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Memproses Log Out...";

      const response = await this.$post("admin/logout");

      if (response.status === 200) {
        TokenService.removeToken();

        // this.$store.state.snackbar = {
        //   show: true,
        //   text: "Tunggu sebentar, Anda akan diarahkan ke halaman Login",
        //   color: "#3498db",
        // };

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        this.$store.state.overlay.show = false;
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },
  },
};
</script>

<style>
.bg-gradient-green {
  background: #4ab762; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4ab762,
    #a8e063
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4ab762,
    #a8e063
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.v-list--nav .menu-active .v-list-item__title.subtitle-2,
.v-list--nav .menu-active .v-list-item__action .v-icon {
  font-weight: bold;
  color: #34495e !important;
}

.v-list--nav .v-list-group > .menu-active .v-list-item__title.subtitle-2,
.v-list--nav .v-list-group > .menu-active .v-list-item__icon .v-icon {
  color: #34495e !important;
}

/* .menu-active:not(.v-list-group__header):after { */
.v-list--nav .menu-active:after {
  position: absolute;
  right: 0px;
  border-right: 6px solid #34495e;
}

.v-list--nav .v-list-item__title.subtitle-2,
.v-list--nav .v-list-item__action .v-icon,
.v-list--nav .v-list-item__icon .v-icon {
  color: #efefef !important;
}

.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: 0 !important;
}

.v-list--nav .v-list-item__action:first-child,
.v-list--nav .v-list-item__icon:first-child {
  margin-right: 12px !important;
}

.v-navigation-drawer--mini-variant .v-list--nav .v-list-item > *:first-child {
  margin-right: 0 !important;
}

.v-list--nav .v-list-group .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 0 !important;
}

.v-list--nav .v-list-group {
  margin-bottom: 8px !important;
}

.v-list--nav .v-list-item__icon {
  margin: 12px 0 !important;
}

.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 42px !important;
}

.list-item--active::before {
  opacity: 0.08 !important;
}

.v-list--nav .v-list-item--active:focus::before {
  opacity: 0.1 !important;
}

.v-application .v-progress-linear__background.primary,
.v-application .v-progress-linear__indeterminate.primary {
  background-color: #4ab762 !important;
  border-color: #4ab762 !important;
}

.v-data-table > .v-data-table__wrapper {
  background-color: #fff;
  color: #848e92 !important;
}

.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:not(.v-data-table__mobile-row),
.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid #f7f7f7 !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: #f8f8f9 !important;
  border-top: thin solid #f7f7f7 !important;
  font-weight: bold !important;
  border-bottom: thin solid #f7f7f7 !important;
}

.v-data-table.v-data-table--fixed-header thead th {
  box-shadow: unset !important;
  color: #9e9e9e !important;
}

.v-data-footer {
  color: #848e92;
}

.v-data-table > .v-data-table__wrapper .v-data-table__checkbox .v-icon {
  color: rgb(74 183 98 / 90%) !important;
}

.v-data-table tbody tr.v-data-table__selected {
  background: rgb(74 183 98 / 15%) !important;
}

.my-custom-dialog {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0px !important;
  border-radius: 0 !important;
  max-height: 100% !important;
  overflow-y: unset !important;
}

.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  box-shadow: none !important;
  border: 1px solid #ebebeb;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  border: 0.25px solid #4ab762;
  border-width: 0.25px !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.v-text-field--solo.v-input--is-focused.error--text
  > .v-input__control
  > .v-input__slot {
  background: #f9f9f9 !important;
  border: 0.25px solid #e74c3c !important;
  border-width: 0.25px !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.v-input__prepend-inner {
  margin-right: 6px !important;
}

::placeholder {
  color: rgba(80, 80, 80, 0.3) !important;
  font-size: 16px !important;
}

.v-application .error--text {
  color: unset !important;
  caret-color: unset !important;
}

.checkbox-pwd .v-label {
  font-size: 14px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: unset;
  margin-bottom: unset;
  position: absolute;
  right: 0;
  margin-top: 8px;
}

.v-text-field.v-text-field--enclosed .v-input__control {
  position: relative;
}

.v-text-field input {
  max-width: calc(100% - 25px) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.v-list-item__subtitle a {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-application a {
  color: #4ab762 !important;
}

.v-breadcrumbs .v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.checkbox-pwd .v-label {
  font-size: 14px !important;
}
</style>