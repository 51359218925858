import Vue from "vue";
import VueRouter from "vue-router";

import { TokenService } from "@/services/service.js";

import LayoutLogin from "@/views/layout/Login";
import LayoutAdmin from "@/views/layout/Admin";
import Layout404 from "@/views/layout/404";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/page/Login"),
    meta: {
      title: "Login",
      auth: "public",
      layout: LayoutLogin,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("@/views/page/ForgotPassword"),
    meta: {
      title: "Lupa Password",
      auth: "public",
      layout: LayoutLogin,
    },
  },
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/page/Dashboard"),
    meta: {
      title: "Dashboard",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/page/User"),
    meta: {
      title: "User",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/publisher",
    name: "publisher",
    component: () => import("@/views/page/Publisher"),
    meta: {
      title: "Publisher",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/organization",
    name: "organization",
    component: () => import("@/views/page/Organization"),
    meta: {
      title: "Organisasi",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/collection",
    name: "collection",
    component: () => import("@/views/page/Collection"),
    meta: {
      title: "Kategori Koleksi Kelas",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/promo-discount",
    name: "promoDiscount",
    component: () => import("@/views/page/PromoDiscount"),
    meta: {
      title: "Promo Diskon",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/approval-promo-discount",
    name: "approvalPromoDiscount",
    component: () => import("@/views/page/ApprovalPromoDiscount"),
    meta: {
      title: "Approval Promo Diskon",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/voucher",
    name: "voucher",
    component: () => import("@/views/page/Voucher"),
    meta: {
      title: "Voucher",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/page/Profile"),
    meta: {
      title: "Ubah Profil",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/password",
    name: "password",
    component: () => import("@/views/page/Password"),
    meta: {
      title: "Ubah Password",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/laporan-transaksi-global",
    name: "Laporan Transaksi kelas",
    component: () => import("@/views/page/report/Global.vue"),
    meta: {
      title: "Laporan Transaksi Kelas",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/laporan-transaksi-organisasi",
    name: "Laporan Transaksi Kelas Organisasi",
    component: () => import("@/views/page/report/Organitation.vue"),
    meta: {
      title: "Laporan Transaksi Kelas Berdasarkan Organisasi",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/laporan-transaksi-publisher",
    name: "Laporan Transaksi kelas publisher",
    component: () => import("@/views/page/report/Publisher.vue"),
    meta: {
      title: "Laporan Transaksi Kelas Berdasarkan Publisher",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "/notification",
    name: "Notifikasi",
    component: () => import("@/views/page/Notification.vue"),
    meta: {
      title: "Notifikasi",
      auth: "admin",
      layout: LayoutAdmin,
    },
  },
  {
    path: "*",
    name: "notFound",
    meta: {
      title: "404",
      auth: "public",
      layout: Layout404,
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  
  const getUser = TokenService.getUser();
  if (getUser) {
    store.commit("setUser", TokenService.getUser());
  } else {
    store.commit("falseToken");
  }

  window.document.title = to.meta.title + " | Administrator Otodidak";

  // cek ketika user belum login
  if (to.meta.auth === "admin" && !store.state.authenticated) {
    next({
      name: "login",
    });
    return;
  }

  // jika sudah login tapi mengakses halaman login, maka kembalikan ke halaman dashboard
  if (store.state.authenticated) {
    if ((to.name == "login")) {
      next({
        name: "dashboard",
      });
      return;
    }
  }

  next();
});

export default router;
