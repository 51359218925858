<template>
  <v-app>
    <component v-bind:is="layout" />
  </v-app>
</template>

<script>
  import firebaseapp from 'firebase';
  import firebase from './plugins/Firebase'; 
  export default {
    name: "App",

    components: {},

    data: () => ({}),
    computed: {
      layout() {
        return this.$route.meta.layout;
      },
      token () {
        return this.$store.state.user
      }
    },
    watch: { 
      '$route' (to, from) { 
        this.tokenFirebase()
      } 
    },
    methods: {
      tokenFirebase() {
        if (firebase.messaging.isSupported()) {
          if (!this.token) {
            navigator.permissions.query({ name: 'notifications' })
            .then(function(result) {
              if (result.state === 'granted') {
                firebaseapp.messaging().getToken()
                .then(oldToken => {
                  if (oldToken === localStorage.getItem('TOKEN_FCM')) {
                    console.log('Token delete ', oldToken)
                    firebaseapp.messaging().deleteToken(oldToken)
                    localStorage.setItem('TOKEN_FCM', '')
                  } else {
                    console.log('Token is deleted')
                  }
                })
              }
            })
          } else {
            navigator.permissions.query({ name: 'notifications' })
            .then(function(result) {
              if (result.state !== 'granted') {
                firebaseapp.messaging().requestPermission()
                .then(function() {
                  window.location.reload()
                })
                .catch(function(err) {
                  console.log('Unable to get permission to notify', err)
                })
              }
            })

            firebaseapp.messaging().getToken()
            .then(refreshedToken => {
              // console.log('Token refresh ', refreshedToken)
              this.updateTokenFirebase(refreshedToken)
            })
            .catch(function(err) {
              console.log('Unable to retrieve token', err)
            })
          }
        }
      },
      async updateTokenFirebase(refreshedToken) {
        // console.log(refreshedToken)
        if (refreshedToken !== localStorage.getItem('TOKEN_FCM')) {
          const response = await this.$post(`fcm/update`, {
            fcm_token: refreshedToken
          });
          if (response.status === 200) {
            localStorage.setItem('TOKEN_FCM', refreshedToken)
          }
        }
            // console.log(localStorage.getItem('TOKEN_FCM'))
      }
    },
    created() {
      if (process.browser) {
        if ('Notification' in window && 'serviceWorker' in navigator) {
          this.tokenFirebase()
        }
      }
    }
  };
</script>