<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
export default {
  name: "Layout404",

  data: () => ({}),

  methods: {},
};
</script>

<style>
</style>